.dashboard_main_container {
  display: flex;
  justify-content: space-between;
  max-height: 60px;
}

.dashboard_header_left {
  display: flex;
  align-items: center;
}

.dashboard_header_left ul {
  display: flex;
  list-style-type: none;
  gap: 0.3rem;
  padding-left: 20px !important;
}

.dashboard_header_left ul li {
  /* margin: 0px 10px; */
  font-size: 1em;
  cursor: pointer;
  position: relative;
  padding: 10px 8px;
  font-weight: 500;
  color: var(--Grey2);
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transition: 0.3s ease all; */
}

.dashboard_header_left ul li.selected {
  background: #e0f0f2;
  color: var(--Secondary-green--dark);
  border-radius: 6px;
}

.dashboard_header_left ul li:hover {
  background: #e0f0f2;
  color: var(--Secondary-green--dark);
  border-radius: 6px;
}

.dashboard_header_right {
  display: flex;
  align-items: center;
  padding-right: 1rem;
  cursor: pointer;
}

.clinic_profile_circle {
  width: 40px;
  height: 40px;
  background: var(--Secondary-green--light);
  color: var(--Secondary-green--dark);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.2s ease all;
}

.clinic_profile_circle:hover {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}

.dashboard_header_popover {
  pointer-events: auto;
}

/***  Popover styles ***/
.clinicHeader__popover {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.clinicHeader__popover--name {
  font-weight: 600;
  white-space: nowrap;
}

.clinicHeader__popover--list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.4rem;
  color: var(--color-grey-700);
}

.clinicHeader__popover--list div {
  cursor: pointer;
}

.clinicHeader__popover--list .logout {
  color: var(--Secondary-green--dark);
}
