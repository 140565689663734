.cpt_favourites_container {
    height: 400px;
    width: 400px;
    padding: 2rem 2rem 2rem 2rem;
    overflow-x: hidden;
}

.favCptListContainer {
    height: 285px;
    

}

.favCptList {
    border-radius: 10px;
    display: flex;
    border: 1px solid #c5c5c5;
    padding: 0.5rem 1rem 0.5rem;
    margin-bottom: 10px;
}

.favCptCode {
    margin-right: 5px;
    color: #14a6a6;
    display: flex;
    align-items: center;
    width: 75%;
}

.favCptDec {
    margin-right: 25px;
    width: 75%;
}

.favCptIcons {
    cursor: pointer;
    color: #828282;
    position: relative;
    display: flex;
    align-items: center;
}

/* .button_container {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    padding-top: 1rem;
    position: absolute;
    width: 84%;
    background-color: #fff;
    bottom: 0;
    padding-bottom: 1rem;
} */
.button_container {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    padding-top: 1rem;
    background-color: #fff;
    padding-bottom: 1rem;
}
