.rounding_main_container {}

.rounding_main_panel_container {
  display: grid;
  width: 100%;
  max-height: calc(100vh - 130px);
  grid-template-columns: 1fr 1fr;
}

.rounding_table_main_container {
  /* width: 50vw; */
}

.rounding_second_panel {
  /* width: 50vw; */
  /* width: 850px; */
  height: 100%;
  /* height: calc(100vh - 180px); */
  background-color: #eae7e7;
  padding: 1.5rem;
}

.rounding_second_panel_card {
  height: 92%;
  border-radius: 12px;
  background-color: #ffff;
  padding: 1.5rem;
}

.rounding_second_panel_card_item {
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: flex-end;
}

.rounding_second_panel_card_item_note {
  padding-bottom: 1rem;
  display: flex;
  /* justify-content: space-between; */
  gap: 1rem;
  align-items: flex-end;
}

.rounding_second_panel_card_first_item {
  padding-bottom: 0.6rem;
  border-bottom: 1px solid #d7e0e9;
}

.rounding_second_panel_card_item_row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 3px;
}

.rounding_second_panel_card_heading {
  font-size: 18px;
  font-weight: 600;
}

.rounding_action_link {
  color: #14a6a6;
  text-decoration: underline;
  font-size: 15px;
  padding-top: 3px;
  cursor: pointer;
}

.rounding_action_link:hover {
  color: #0f6262;
}

.rounding_second_panel_card_content {
  font-size: 16px;
  font-weight: 500;
}

.rounding_filter_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d7e0e9;
  padding-top: 8px;
}

.rounding_pagination_container {
  padding-top: 10px;
}

.rounding_filters {
  display: flex;
  gap: 16px;
  padding-right: 20px;
  padding-bottom: 5px;
}

.rounding_filter_dropdown {
  min-width: 150px;
  max-width: 150px;
}

.rounding_filter_button {
  padding-top: 12px;
}

.rounding_table_head_cell {
  background-color: #e7f7f4 !important;
}

.rounding_table_head_cell.MuiTableCell-sizeSmall {
  padding: 2px 8px 2px 8px !important;
}

.rounding_table_body_cell.MuiTableCell-sizeSmall {
  padding: 0 8px 0 8px !important;
}

.rounding_table_body_cell.patient {
  min-width: 80px;
}

.rounding_action_icons_container {
  display: flex;
  gap: 5px;
}

.rounding_action_icon {
  cursor: pointer;
}

.rounding_selected_row {
  background-color: #e9f0ef;
}

.ipop_button_selected {
  background-color: #14a6a6 !important;
  color: white !important;
}

.ipop_button_group .MuiButton-outlinedSizeSmall {
  padding: 0 9px !important;
}

.add_new_icon_container {
  width: 100%;
  position: absolute;
  bottom: 5%;
  left: 100%;
  z-index: 100;
}

.add_new_icon {
  height: 50px;
  width: 50px;
  background: var(--Secondary-green);
  border-radius: 50%;
  display: flex;
  float: right;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease all;
  z-index: 10;
  position: fixed;
  bottom: 12vh;
  left: 37vw;
  cursor: pointer;
}

.add_new_icon:hover {
  background: var(--Secondary-green--hover);
}

.new_rounding_modal_container {
  padding: 2rem;
}

.notesTabForm_container {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.notes_input_container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 1.2rem;
  width: 100%;
}

.note_text_field {
  width: calc(40vw - 70px);
}

.icd_search_items_container {
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: flex-end;
  width: 100%;
}

.icd_search_item {
  display: flex;
  width: 35%;
  margin-right: -40px;
}

.cpt_icons {
  cursor: pointer;
  position: relative;
  bottom: 26px;
  right: -70px;
}

.icd_search_icon {
  cursor: pointer;
  position: relative;
  bottom: -21px;
  right: 75px;
}

.cpt_ip_op {
  color: #adb2bc;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.selected_ip_op {
  color: #139696;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}

.cpt_tabs_header {
  background-color: #e7f7f4;
  color: #139696 !important;
  font-weight: 600 !important;
}

.cpt_tabs_header .MuiTab-root {
  min-width: 20% !important;
  font-weight: 600;
  color: #139696;
  padding: 0 12px !important;
}

@media (min-width: 600px) {
  .cpt_tabs_header .MuiTab-root {
    min-width: 20% !important;
  }
}

.cpt_chip.MuiChip-root {
  border-radius: 6px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.cpt_chip .MuiChip-labelSmall {
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.cpt_chips_container {
  display: flex;
  gap: 10px;
}

.cpt_card_container {
  border: 1px solid #a3acb9;
  border-radius: 6px;
  padding: 10px 15px;
  min-width: 44%;
}

.cpt_card_row1 {
  display: flex;
  /* gap: 5px; */
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;
}

.cpt_card_row2 {
  display: flex;
  /* gap: 5px; */
  justify-content: space-between;
}

.cpt_code {
  font-weight: 600;
  height: 24px;
}

.cpt_modifier {
  font-size: 14px;
  color: #a3acb9;
  cursor: pointer;
}

.cpt_detail {
  font-size: 14px;
  color: #697386;
}

.rounding_input_item {
  width: 100%;
}

.rounding_input_item_date {
  margin-top: 0 !important;
}

.cpt_text_field .MuiInput-underline:before {
  bottom: 5px;
}

.cpt_text_field .MuiInput-underline:after {
  bottom: 5px;
}