.census_main_container {
}

.census_filter_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d7e0e9;
  padding-top: 8px;
}

.census_pagination_container {
  padding-top: 10px;
  padding-left: 3px;
}

.census_filters {
  display: flex;
  gap: 16px;
  padding-right: 20px;
  padding-bottom: 5px;
}

.census_filter_dropdown {
  min-width: 150px;
  max-width: 150px;
}

.census_filter_datePicker {
  max-width: 150px;
}

.census_filter_button {
  padding-top: 12px;
}

.census_table_main_container {
  overflow-y: auto;
}

.census_table_head_cell {
  background-color: #e7f7f4 !important;
}

.census_table_head_cell.MuiTableCell-sizeSmall {
  padding: 7px 8px 7px 8px !important;
}

.census_table_body_cell.MuiTableCell-sizeSmall {
  padding: 5px 8px 5px 8px !important;
}

.census_table_body_cell.actions {
  display: flex;
  gap: 12px;
}

.view_button {
  color: #15afaf;
  text-decoration: underline;
  cursor: pointer;
  padding: 6px 0 7px !important;
}

.view_button:hover {
  color: #0f6262;
}

.image_download_icon_container {
  cursor: pointer;
  padding: 6px 0 7px !important;
}

.image_download_icon {
  height: 18px;
  padding: 0 4px;
}

.image_download_icon:hover {
  background-color: #f2f2f2;
  border-radius: 4px;
}

.census_table_body_cell .MuiInputBase-root {
  font-size: 14px;
}

.census_table_body_cell .MuiInput-formControl {
  margin-top: 0 !important;
}
