.clinicLogin {
  /* height: 100vh; */
  font-family: "Montserrat", sans-serif;
  position: relative;
  background: #fff;
  margin: -8px;
}

.trillium-health-logo-header {
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e0e0e0;
}

.trillium-health-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100%;
  width: 90%; */
}

.trillium-health-logo {
  width: 160px;
  padding-top: 20px;
}

.clinicLogin__main {
  padding: 4rem 2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clinicLogin__section {
  display: flex;
  /* width: 80%;
  height: 80%; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* .clinicLogin__grid {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  place-items: center;
} */

.clinicLogin__grid__col--form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clinicLogin__grid__heading {
  font-size: 32px;
  letter-spacing: initial;
  font-weight: 600;
  /* margin-bottom: 1rem; */
  line-height: 40px;
  color: #4f566b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clinicLogin__grid__subheading {
  font-size: 28px;
  letter-spacing: initial;
  line-height: 32px;
  font-weight: 500;
  margin-top: 8rem;
  margin-bottom: 3rem;
  color: #4f566b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clinicLogin__form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clinicLogin__inputField {
  width: 270px !important;
}

.login_input {
  height: 70px;
}

.clinicLogin__editableInputField {
  margin-left: 20px;
}

.clinicLogin__editBtn--wrapper {
  height: 90%;
  position: absolute;
  top: 13px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnWrapper {
  margin-top: 15px;
}

.clinicLogin__submitBtn {
  width: 100% !important;
  color: white !important;
  border: 0;
  cursor: pointer;
  height: 45px;
  padding: 10px 13px;
  font-size: 16px;
  min-width: 310px;
  background: #088b8c;
  min-height: 30px;
  /* transition: 0.3s ease all; */
  font-weight: bold;
  border-radius: 4px;
  box-shadow: none !important;
  margin-top: 20px;
}

.clinicLogin__submitBtn:hover {
  background: #10a8a9;
}

/* @media (max-width: 1440px) {
  .clinicLogin__main {
    max-width: 1240px;
  }
}

@media (max-width: 1024px) {
  .clinicLogin__main {
    max-width: 800px;
  }
  .clinicLogin__heroImg {
    left: -40px;
  }
} */

@media (max-width: 768px) {
  .clinicLogin__grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }
  .clinicLogin__grid__col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .clinicLogin__section {
    margin: 40px 72px;
  }
  .clinicLogin__header__bar ul li a {
    font-size: 0.9rem;
  }
}

@media (max-width: 425px) {
  .clinicLogin__header__bar {
    flex-direction: column;
    padding: 1rem 0rem;
  }
  .clinicLogin__section {
    margin: 20px;
  }
}

.MuiFormHelperText-contained {
  margin-left: 0px !important;
}
