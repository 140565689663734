/* ********* Global Color styling for the full app ********* */
:root {
  --main-bg-color: #f3f2ef;
  --Grey1: #333333;
  --Grey2: #4f4f4f;
  --Grey3: #828282;
  --Grey4: #bdbdbd;
  --Grey5: #e0e0e0;
  --Grey5--hover: #c5c5c5;
  --Grey6: #f2f2f2;
  --Blue: #4a9ef7;
  --Blue-hover: #1f86f4;
  --Container-grey: #f0f0f0;
  --Container-grey-hover: #d1d1cf;
  --Container-text: #707683;
  --Secondary-green: #14a6a6;
  --Secondary-green--hover: #0d7979;
  --Secondary-green--hoverLight: rgba(181, 255, 255, 0.2);
  --Secondary-green--light: #e0f0f2;
  --Secondary-green--dark: #139696;
  --line-grey: #d7e0e9;

  --color-grey-50: #f7fafc;
  --color-grey-100: #e3e8ee;
  --color-grey-dark-100: #7a8394;
  --color-grey-200: #c1c9d2;
  --color-grey-300: #a3acb9;
  --color-grey-400: #8792a2;
  --color-grey-500: #697386;
  --color-grey-600: #59617b;
  --color-grey-700: #3c4257;
  --color-grey-800: #2a2f45;
  --color-grey-900: #1a1f36;
}

/** Custom scrollbar for all 'Overflows Y and X' 
[Always reuse the className="scrollbarY--custom"] on div element
if scrollbar is needed*/
.scrollbarY--custom::-webkit-scrollbar {
  width: 0.4em;
  height: 0.6rem;
  border-radius: 0px 10px 0px 0px;
}

.scrollbarY--custom::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.scrollbarY--custom::-webkit-scrollbar-thumb {
  background-color: rgb(182, 182, 182);
  border-radius: 10px 20px 10px 10px;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.header {
  /* border-bottom: 1px solid var(--line-grey); */
}

.header img {
  width: 226px;
}
.link,
a {
  color: var(--link--blue);
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 8px;
}

.text-black {
  color: #000000;
}

.text-grey {
  color: var(--color-grey-400) !important;
}
.center,
.center-column,
.top,
.right,
.bottom,
.left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-column {
  flex-direction: column;
}

.top {
  align-items: flex-start;
}
.right {
  justify-content: flex-end;
}
.bottom {
  align-items: flex-end;
}
.left {
  justify-content: flex-start;
}

.space-between {
  justify-content: space-between;
}

.gap-xs {
  gap: 8px;
}

.width-100 {
  width: 100%;
}

.fw-500 {
  font-weight: 500 !important;
}

.p-xs {
  padding: 8px;
}
.p-sm {
  padding: 16px !important;
}
.p-smd {
  padding: 24px;
}
.p-md {
  padding: 32px !important;
}
.p-lg {
  padding: 48px;
}

.m-xs {
  margin: 8px !important;
}
.m-sm {
  margin: 16px !important;
}
.m-smd {
  margin: 24px !important;
}

.br-8 {
  border-radius: 8px;
}

.text-center {
  text-align: center;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 100;
}

.single-spaced,
.single-spaced * {
  line-height: 1;
}

.MuiTypography-gutterBottom {
  margin-bottom: 8px !important;
}

@media (max-width: 1024px) {
  .desktop {
    display: none;
  }
}

.MuiStepIcon-text {
  fill: #fff !important;
}

.stepper__wrapper {
  max-width: 1440px;
  margin: auto;
  margin-top: 64px;
}

.stepper__numbers {
  max-width: 1024px;
  margin: auto;
}

.stepper__content {
  /* border: 1px solid red; */
  /* max-width: 1024px; */
  max-width: 768px;
  margin: auto;
}

.stepper__content__formFields {
  padding: 1rem 0;
}
.forms_margin {
  padding: 1rem 0;
}

.stepper__content__wrapper {
  padding-bottom: 3rem;
}

.showShadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.border {
  border: 1px solid red;
}

.form__btnWrapper {
  display: flex;
  padding-top: 1.5rem;
  gap: 8px;
  justify-content: flex-end;
}

/* --------------- CLINIC DETAILS ------------- */

/* -------------------------------------------- */

/* ------------- PROVIDERS ------ */
.container {
  width: 100%;
  background: var(--container-grey);
  min-height: 300px;
  border: 1px solid var(--line-grey);
  border-radius: 8px;
}

.container__header {
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 8px 24px;
}

.container__listItems {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.container__listItem {
  background: #fff;
  border: 1px solid var(--line-grey);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.container__listItemDetails {
  display: flex;
  gap: 1rem;
}

.dialogue__container {
  padding: 1.5rem !important;
}

.MuiDialogContent-root {
  overflow-y: hidden !important;
}

/* .MuiCollapse-root.MuiCollapse-entered {
	padding: 16px !important;
} */

/* ------------------------------------------ */

/* ------------- UPLOAD ------ */

/* ------------------------------------------ */
